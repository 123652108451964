<template>
  <div>
    <ServerError v-if="ServerError" />

    <v-app-bar color="#000" dense dark class="hidden-md-and-up">
      <v-app-bar-nav-icon @click.native="sideNav = !sideNav">
        <v-icon color="#FFF">mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <Logo />
    </v-app-bar>
    <v-navigation-drawer
      v-model="sideNav"
      fixed
      temporary
      width="220px"
      class="hidden-md-and-up"
      color="#000"
    >
      <v-layout wrap justify-center>
        <!-- <v-flex xs12 text-center>
         <Logo />
        
        </v-flex> -->
        <v-flex xs12 text-left pa-2 text-uppercase align-self-center>
          <v-list dense nav>
            <v-list-item v-for="item in headerItems" :key="item.title" link>
              <!-- <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon> -->

              <v-list-item-content>
                <v-list-item-title
                  style="
                    color: #adadad;
                    font-family: sofiaProMedium;
                    font-size: 15px;
                  "
                >
                  <span
                    style="cursor: pointer"
                    @click="$router.push(item.path)"
                    >{{ item.title }}</span
                  >
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>

    <v-app-bar
      color="#000"
      app
      fixed
      dense
      flat
      style="padding-left: 0px !important"
      class="hidden-sm-and-down"
      :height="$vuetify.breakpoint.name == 'xl' ? '90px' : '60px'"
    >
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

      <v-layout wrap fill-height justify-start px-4>
        <v-flex xs2 align-self-center>
          <router-link to="/">
            <Logo />
          </router-link>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex
          sm10
          md9
          lg8
          xl6
          :class="$vuetify.breakpoint.name == 'sm' ? 'text-left' : 'text-right'"
          align-self-center
        >
          <v-layout wrap justify-start>
            <v-flex
              align-self-center
              v-for="item in headerItems"
              :key="item.value"
            >
              <v-layout @click="$router.push(item.path)">
                <span
                  style="
                    cursor: pointer;
                    color: #adadad;
                    font-family: sofiaProMedium;
                    font-size: 15px;
                    text-transform: uppercase;
                  "
                >
                  <v-layout pb-1>
                    {{ item.title }}
                  </v-layout>

                  <v-layout v-if="item.value == 'works'">
                    <v-progress-linear
                      v-if="$route.path == '/products'"
                      color="#B88726"
                      value="100"
                    ></v-progress-linear>
                  </v-layout>
                  <v-layout v-else>
                    <v-progress-linear
                      v-if="$route.path == item.path"
                      color="#B88726"
                      value="100"
                    ></v-progress-linear>
                  </v-layout>
                </span>
                <!-- <span
                  v-else
                  style="
                    color: #adadad;
                    font-family: sofiaProMedium;
                    font-size: 15px;
                    text-transform: uppercase;
                  "
                >
                  {{ item.title }}</span
                > -->
              </v-layout>
            </v-flex>
            <v-flex> </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-app-bar>
  </div>
</template> 
<script>
import axios from "axios";
export default {
  data() {
    return {
      ServerError: false,
      sideNav: false,
      selectedItem: null,
      selectedHeader: null,
      headerItems: [
        {
          title: "Home",
          value: null,
          path: "/",
        },

        {
          title: "About Us",
          value: null,
          path: "/about",
        },
        {
          title: "Services",
          value: null,
          path: "/services",
        },
        // {
        //   title: "Our Clients",
        //   value: null,
        //   // path: "/clients",
        //    path: "/comingsoon",
        //   nameValue: "resolvedCases",
        // },

        {
          title: "Our Works",
          value: "works",
          path: "/products?category=612dae376c74ee79eea1e3e0",
        },
        {
          title: "Careers",
          value: null,
          path: "/comingsoon",
        },
        {
          title: "Internship",
          value: null,
          path: "/InternHomePage",
          // path: "/InternshipDetails",
        },
        {
          title: "Contact",
          value: null,
          path: "/Contact",
        },
      ],
      products: [],
    };
  },
  mounted() {
    this.getCategoryData();
  },
  methods: {
    getCategoryData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "product/category/getlist",
      })
        .then((response) => {
          if (response.data.status) {
            this.products = response.data.data;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style>
.appHeaderActiveClass {
  font-family: sofiaProSemiBold;
  color: #000 !important;
}
.appHeaderNormalClass {
  font-family: sofiaProRegular;
  color: #838383 !important;
}
.v-application--is-ltr
  .v-list--dense
  .v-list-group--sub-group
  .v-list-group__header {
  padding-left: 0px !important;
}

.v-toolbar__content,
.v-toolbar__extension {
  padding: 4px 0px !important;
}

.font-size-md {
  font-size: 12px;
}
.font-size-other {
  font-size: 16px;
}
</style>

